<template>
  <main>
    <trac-loading v-show="isLoading" />
    <trac-back-button>
      Back
    </trac-back-button>
    <p class=" text-lg text-gray-800 font-semibold mt-5 ml-3 ">Kyc</p>
    <div class="  w-full bg-white rounded-md mt-12 big-shadow p-5 h-96">
      <div>
        <h1 class="text-md mt-2 font-medium">Contact Details</h1>
      </div>
      <div class="flex flex-col mt-10 ">
        <p class=" text-sm">Edit your phone number</p>
        <div class=" w-96 mt-6">
          <trac-input disabled placeholder="Phone Number" type="text" v-model="number">
          </trac-input>
        </div>
      </div>

      <!-- <div class="shadow-md rounded pl-10 pr-10 pt-10 pb-10 ml-12 mr-12 mt-10">
      <img
        src="./../../assets/svg/terminal.svg"
        alt=""
        class="block m-auto mb-8"
      />
      <trac-button class="m-auto block" @button-clicked="gotoTermianals"
        >Terminals</trac-button
      >
    </div> -->
    </div>
  </main>
</template>

<script>
import {
  GET_USER_BUSINESS_ID,
  GET_USER_BUSINESS_DATA,
} from "../../browser-db-config/localStorage";
import { eventBus } from "./../../main";

export default {
  data() {
    return {
      number: "",
      isLoading: false,
      isCustomKycRoute: false,
    };
  },
  methods: {
    async verifyPhone() {
      if (this.number === null) {
        eventBus.$emit("trac-alert", {
          message: "Enter Your Phone Number",
        });
        return;
      }
      let arr = ["080", "081", "090", "091", "070", "071"];
      let test1 = arr.some((el) => this.number.startsWith(el));

      // if (!test1) {
      //   eventBus.$emit("trac-alert", {
      //     message: "Phone Number not valid",
      //   });
      //   return;
      // }
      if (+this.number.length !== 11) {
        eventBus.$emit("trac-alert", {
          message: "Phone Number should be 11 degits.",
        });
        return;
      }

      this.isLoading = true;
      let payload = {
        reference: this.number,
        verificationType: "PHONE",
        businessId: GET_USER_BUSINESS_ID(),
      };
      let res = await this.$store.dispatch("SEND_OTP", payload);
      if (res.status) {
        eventBus.$emit("trac-alert", {
          message: "OTP sent succesfully, kindly check your phone",
        });
        this.$router.push({
          name: "VerifyPhoneOtp", query: {
            custom: this.isCustomKycRoute,
          }
        });
      }
      this.isLoading = false;
    },
  },
  mounted() {
    this.number = GET_USER_BUSINESS_DATA().phone;
    this.isCustomKycRoute = this.$route.query.custom;
  },
};
</script>

<style></style>
